<template>
  <notifications
    class="m-3"
    position="bottom right"
    animation-name="v-fade-left"
  >
    <template slot="body" slot-scope="props">
      <div class="toast show text-black">
        <div class="toast-header">
          <strong
            class="me-auto"
            v-html="getTitle(props.item.title, props.item.type)"
          ></strong>
          <!--          <small>11 mins ago</small>-->
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="toast"
            aria-label="Close"
            @click="props.close"
          ></button>
        </div>
        <div class="toast-body" v-html="props.item.text"></div>
      </div>
    </template>
  </notifications>
</template>

<script>
export default {
  name: "Notify",
  methods: {
    getTitle(title, type) {
      if (title) return title;
      switch (type) {
        case "error":
          return "Ошибка";
        case "success":
          return "Успешно";
        default:
          return "Уведомление";
      }
    },
  },
};
</script>

<style scoped></style>
