import axios from "axios";
import Vue from "vue";

let api = axios.create({
  baseURL: process.env.VUE_APP_API_URI,
  headers: {
    "Content-type": "application/json",
  },
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.data.errors) {
      Vue.notify({
        type: "error",
        text: Object.values(error.response.data.errors).reduce(
          (pV, cV) => pV + cV.join("\n"),
          ""
        ),
        duration: 5000,
      });
    } else {
      Vue.notify({
        type: "error",
        text: error.response.data.message,
      });
    }
    throw error;
  }
);

export default api;
