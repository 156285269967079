import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/layouts/dashboard"),
    meta: {
      auth: true,
    },
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("@/views/Home"),
      },
      {
        path: "/overview",
        component: () => import("@/layouts/empty"),
        children: [
          {
            path: "",
            name: "Orders today",
            redirect: () => {
              let date = new Date();
              return {
                path: `/overview/${date.getFullYear()}/${date.getMonth() + 1}`,
              };
            },
          },
          {
            path: "order",
            component: () => import("@/layouts/empty"),
            name: "OrderViewsNested",
            children: [
              {
                path: "add",
                name: "Order create",
                component: () => import("@/views/Overview/order/create.vue"),
                meta: {
                  breadCrumb() {
                    return [
                      { text: "План", link: "/overview" },
                      { text: "Создание заказа" },
                    ];
                  },
                },
              },
              {
                path: ":order",
                component: () => import("@/layouts/empty"),
                children: [
                  {
                    path: "",
                    props: { mode: "view" },
                    name: "Order view",
                    component: () =>
                      import("@/views/Overview/order/viewEdit.vue"),
                    meta: {
                      breadCrumb(route) {
                        const order = route.params.order;
                        return [
                          { text: "План", link: "/overview" },
                          { text: `Просмотр заказа ${order}` },
                        ];
                      },
                    },
                  },
                  {
                    path: "edit",
                    props: { mode: "edit" },
                    name: "Order edit",
                    component: () =>
                      import("@/views/Overview/order/viewEdit.vue"),
                    meta: {
                      breadCrumb(route) {
                        const order = route.params.order;
                        return [
                          { text: "План", link: "/overview" },
                          { text: `Редактирование заказа ${order}` },
                        ];
                      },
                    },
                  },
                  {
                    path: "design",
                    component: () => import("@/layouts/empty"),
                    children: [
                      {
                        path: "",
                        name: "CreateDesign",
                        props: { mode: "create" },
                        component: () =>
                          import("@/views/Overview/order/design/viewEdit.vue"),
                        meta: {
                          breadCrumb(route) {
                            const order = route.params.order;
                            return [
                              { text: "План", link: "/overview" },
                              {
                                text: `Заказ ${order}`,
                                link: `/overview/order/${order}`,
                              },
                              { text: "Создание нормативного расчета" },
                            ];
                          },
                        },
                      },
                      {
                        path: ":design",
                        component: () => import("@/layouts/empty"),
                        children: [
                          {
                            path: "",
                            name: "ViewDesign",
                            props: { mode: "view" },
                            component: () =>
                              import(
                                "@/views/Overview/order/design/viewEdit.vue"
                              ),
                            meta: {
                              breadCrumb(route) {
                                const order = route.params.order;
                                const design = route.params.design;
                                return [
                                  { text: "План", link: "/overview" },
                                  {
                                    text: `Заказ ${order}`,
                                    link: `/overview/order/${order}`,
                                  },
                                  { text: `Нормативный расчет ${design}` },
                                ];
                              },
                            },
                          },
                          {
                            path: "edit",
                            name: "EditDesign",
                            props: { mode: "edit" },
                            component: () =>
                              import(
                                "@/views/Overview/order/design/viewEdit.vue"
                              ),
                            meta: {
                              breadCrumb(route) {
                                const order = route.params.order;
                                const design = route.params.design;
                                return [
                                  { text: "План", link: "/overview" },
                                  {
                                    text: `Заказ ${order}`,
                                    link: `/overview/order/${order}`,
                                  },
                                  {
                                    text: `Редактирование нормативного расчета ${design}`,
                                  },
                                ];
                              },
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "workTask",
                    component: () => import("@/layouts/empty"),
                    children: [
                      {
                        path: "",
                        name: "CreateWorkTask",
                        props: { mode: "create" },
                        component: () =>
                          import(
                            "@/views/Overview/order/workTask/viewEdit.vue"
                          ),
                        meta: {
                          breadCrumb(route) {
                            const order = route.params.order;
                            return [
                              { text: "План", link: "/overview" },
                              {
                                text: `Заказ ${order}`,
                                link: `/overview/order/${order}`,
                              },
                              { text: "Создание работы" },
                            ];
                          },
                        },
                      },
                      {
                        path: ":workTask",
                        component: () => import("@/layouts/empty"),
                        children: [
                          {
                            path: "",
                            name: "ViewWorkTask",
                            props: { mode: "view" },
                            component: () =>
                              import(
                                "@/views/Overview/order/workTask/viewEdit.vue"
                              ),
                            meta: {
                              breadCrumb(route) {
                                const order = route.params.order;
                                const workTask = route.params.workTask;
                                return [
                                  { text: "План", link: "/overview" },
                                  {
                                    text: `Заказ ${order}`,
                                    link: `/overview/order/${order}`,
                                  },
                                  { text: `Работа ${workTask}` },
                                ];
                              },
                            },
                          },
                          {
                            path: "edit",
                            name: "EditWorkTask",
                            props: { mode: "edit" },
                            component: () =>
                              import(
                                "@/views/Overview/order/workTask/viewEdit.vue"
                              ),
                            meta: {
                              breadCrumb(route) {
                                const order = route.params.order;
                                const workTask = route.params.workTask;
                                return [
                                  { text: "План", link: "/overview" },
                                  {
                                    text: `Заказ ${order}`,
                                    link: `/overview/order/${order}`,
                                  },
                                  { text: `Редактирование работы ${workTask}` },
                                ];
                              },
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: ":year/:month",
            name: "Overview",
            component: () => import("@/views/Overview/Overview.vue"),
            meta: {
              breadCrumb() {
                return [{ text: "План" }];
              },
            },
          },
        ],
      },
      {
        path: "/references",
        name: "References",
        component: () => import("@/views/References/index"),
        children: [
          {
            path: "labor-code",
            component: () => import("@/views/References/index"),
            children: [
              {
                path: "",
                name: "Labor code list",
                component: () =>
                  import("@/views/References/standards/labor-code/list.vue"),
                meta: {
                  breadCrumb() {
                    return [
                      { text: "Справочник" },
                      { text: "Нормативы" },
                      { text: "Часовые тарифные ставки труда" },
                    ];
                  },
                },
              },
              {
                path: "add",
                name: "Labor code create",
                component: () =>
                  import("@/views/References/standards/labor-code/create"),
                meta: {
                  breadCrumb() {
                    return [
                      { text: "Справочник" },
                      { text: "Нормативы" },
                      {
                        text: "Часовые тарифные ставки труда",
                        link: "/references/labor-code/",
                      },
                      { text: "Добавление" },
                    ];
                  },
                },
              },
              {
                path: ":laborId",
                component: () => import("@/views/References/index"),
                children: [
                  {
                    path: "",
                    name: "Labor code view",
                    component: () =>
                      import(
                        "@/views/References/standards/labor-code/viewEdit"
                      ),
                    props: { mode: "view" },
                    meta: {
                      breadCrumb(route) {
                        const id = route.params.laborId;
                        return [
                          { text: "Справочник" },
                          { text: "Нормативы" },
                          {
                            text: "Часовые тарифные ставки труда",
                            link: "/references/labor-code/",
                          },
                          { text: `${id}` },
                        ];
                      },
                    },
                  },
                  {
                    path: "edit",
                    name: "Labor code edit",
                    component: () =>
                      import(
                        "@/views/References/standards/labor-code/viewEdit"
                      ),
                    props: { mode: "edit" },
                    meta: {
                      breadCrumb(route) {
                        const id = route.params.laborId;
                        return [
                          { text: "Справочник" },
                          { text: "Нормативы" },
                          {
                            text: "Часовые тарифные ставки труда",
                            link: "/references/labor-code/",
                          },
                          {
                            text: `${id}`,
                            link: `/references/labor-code/${id}`,
                          },
                          { text: "Редактирование" },
                        ];
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "qualification-requirement",
            component: () => import("@/views/References/index"),
            children: [
              {
                path: "",
                name: "Qualification requirements list",
                component: () =>
                  import(
                    "@/views/References/standards/qualification-requirement/list"
                  ),
                meta: {
                  breadCrumb() {
                    return [
                      { text: "Справочник" },
                      { text: "Нормативы" },
                      { text: "Квалификационные требования" },
                    ];
                  },
                },
              },
              {
                path: "add",
                name: "Qualification requirements create",
                component: () =>
                  import(
                    "@/views/References/standards/qualification-requirement/create"
                  ),
                meta: {
                  breadCrumb() {
                    return [
                      { text: "Справочник" },
                      { text: "Нормативы" },
                      {
                        text: "Квалификационные требования",
                        link: "/references/qualification-requirement/",
                      },
                      { text: "Добавление" },
                    ];
                  },
                },
              },
              {
                path: ":qualityId",
                component: () => import("@/views/References/index"),
                children: [
                  {
                    path: "",
                    name: "Qualification requirements view",
                    component: () =>
                      import(
                        "@/views/References/standards/qualification-requirement/viewEdit"
                      ),
                    props: { mode: "view" },
                    meta: {
                      breadCrumb(route) {
                        const id = route.params.qualityId;
                        return [
                          { text: "Справочник" },
                          { text: "Нормативы" },
                          {
                            text: "Квалификационные требования",
                            link: "/references/qualification-requirement/",
                          },
                          { text: `${id}` },
                        ];
                      },
                    },
                  },
                  {
                    path: "edit",
                    name: "Qualification requirements edit",
                    component: () =>
                      import(
                        "@/views/References/standards/qualification-requirement/viewEdit"
                      ),
                    props: { mode: "edit" },
                    meta: {
                      breadCrumb(route) {
                        const id = route.params.qualityId;
                        return [
                          { text: "Справочник" },
                          { text: "Нормативы" },
                          {
                            text: "Квалификационные требования",
                            link: "/references/qualification-requirement/",
                          },
                          {
                            text: `${id}`,
                            link: `/references/qualification-requirement/${id}`,
                          },
                          { text: "Редактирование" },
                        ];
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "work-equipment",
            component: () => import("@/views/References/index"),
            children: [
              {
                path: "",
                name: "Work Equipment list",
                component: () =>
                  import("@/views/References/standards/work-equipment/list"),
                meta: {
                  breadCrumb() {
                    return [
                      { text: "Справочник" },
                      { text: "Нормативы" },
                      { text: "Часовые стоимости эксплуатации оборудования" },
                    ];
                  },
                },
              },
              {
                path: "add",
                name: "Work Equipment create",
                component: () =>
                  import("@/views/References/standards/work-equipment/create"),
                meta: {
                  breadCrumb() {
                    return [
                      { text: "Справочник" },
                      { text: "Нормативы" },
                      {
                        text: "Часовые стоимости эксплуатации оборудования",
                        link: "/references/work-equipment/",
                      },
                      { text: "Добавление" },
                    ];
                  },
                },
              },
              {
                path: ":workId",
                component: () => import("@/views/References/index"),
                children: [
                  {
                    path: "",
                    name: "Work Equipment view",
                    component: () =>
                      import(
                        "@/views/References/standards/work-equipment/viewEdit"
                      ),
                    props: { mode: "view" },
                    meta: {
                      breadCrumb(route) {
                        const id = route.params.workId;
                        return [
                          { text: "Справочник" },
                          { text: "Нормативы" },
                          {
                            text: "Часовые стоимости эксплуатации оборудования",
                            link: "/references/work-equipment/",
                          },
                          { text: `${id}` },
                        ];
                      },
                    },
                  },
                  {
                    path: "edit",
                    name: "Work Equipment edit",
                    component: () =>
                      import(
                        "@/views/References/standards/work-equipment/viewEdit"
                      ),
                    props: { mode: "edit" },
                    meta: {
                      breadCrumb(route) {
                        const id = route.params.workId;
                        return [
                          { text: "Справочник" },
                          { text: "Нормативы" },
                          {
                            text: "Часовые стоимости эксплуатации оборудования",
                            link: "/references/work-equipment/",
                          },
                          {
                            text: `${id}`,
                            link: `/references/work-equipment/${id}`,
                          },
                          { text: "Редактирование" },
                        ];
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "type-material",
            component: () => import("@/views/References/index"),
            children: [
              {
                path: "",
                name: "Type Material list",
                component: () =>
                  import("@/views/References/standards/type-material/list"),
                meta: {
                  breadCrumb() {
                    return [
                      { text: "Справочник" },
                      { text: "Нормативы" },
                      { text: "Типы материалов" },
                    ];
                  },
                },
              },
              {
                path: "add",
                name: "Type Material create",
                component: () =>
                  import("@/views/References/standards/type-material/create"),
                meta: {
                  breadCrumb() {
                    return [
                      { text: "Справочник" },
                      { text: "Нормативы" },
                      {
                        text: "Типы материалов",
                        link: "/references/type-material/",
                      },
                      { text: "Добавление" },
                    ];
                  },
                },
              },
              {
                path: ":typeId",
                component: () => import("@/views/References/index"),
                children: [
                  {
                    path: "",
                    name: "Type Material view",
                    component: () =>
                      import(
                        "@/views/References/standards/type-material/viewEdit"
                      ),
                    props: { mode: "view" },
                    meta: {
                      breadCrumb(route) {
                        const id = route.params.typeId;
                        return [
                          { text: "Справочник" },
                          { text: "Нормативы" },
                          {
                            text: "Типы материалов",
                            link: "/references/type-material/",
                          },
                          { text: `${id}` },
                        ];
                      },
                    },
                  },
                  {
                    path: "edit",
                    name: "Type Material edit",
                    component: () =>
                      import(
                        "@/views/References/standards/type-material/viewEdit"
                      ),
                    props: { mode: "edit" },
                    meta: {
                      breadCrumb(route) {
                        const id = route.params.typeId;
                        return [
                          { text: "Справочник" },
                          { text: "Нормативы" },
                          {
                            text: "Типы материалов",
                            link: "/references/type-material/",
                          },
                          {
                            text: `${id}`,
                            link: `/references/type-material/${id}`,
                          },
                          { text: "Редактирование" },
                        ];
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "import",
            name: "ImportData",
            component: () => import("@/views/References/import-data"),
            meta: {
              breadCrumb() {
                return [{ text: "Справочник" }, { text: "Импорт данных" }];
              },
            },
          },
          {
            path: "technological-maps",
            component: () => import("@/views/References/index"),
            children: [
              {
                path: "",
                name: "Technological maps tree-list",
                component: () =>
                  import("@/views/References/technological-maps/group-maps"),
                meta: {
                  breadCrumb() {
                    return [
                      { text: "Справочник" },
                      { text: "Технологические карты ТОиР" },
                      { text: "Группы технологических карт" },
                    ];
                  },
                },
              },
              {
                path: "condition-factors",
                component: () => import("@/views/References/index"),
                children: [
                  {
                    path: "",
                    name: "Condition Factors list",
                    component: () =>
                      import(
                        "@/views/References/technological-maps/condition-factor/list"
                      ),
                    meta: {
                      breadCrumb() {
                        return [
                          { text: "Справочник" },
                          { text: "Технологические карты ТОиР" },
                          { text: "Поправочные коэффициенты" },
                        ];
                      },
                    },
                  },
                  {
                    path: "add",
                    name: "Condition Factor create",
                    component: () =>
                      import(
                        "@/views/References/technological-maps/condition-factor/create"
                      ),
                    meta: {
                      breadCrumb() {
                        return [
                          { text: "Справочник" },
                          { text: "Технологические карты ТОиР" },
                          {
                            text: "Поправочные коэффициенты",
                            link: "/references/technological-maps/condition-factors/",
                          },
                          { text: "Добавление" },
                        ];
                      },
                    },
                  },
                  {
                    path: ":id",
                    component: () => import("@/views/References/index"),
                    children: [
                      {
                        path: "",
                        name: "Condition Factor view",
                        component: () =>
                          import(
                            "@/views/References/technological-maps/condition-factor/viewEdit"
                          ),
                        props: { mode: "view" },
                        meta: {
                          breadCrumb(route) {
                            return [
                              { text: "Справочник" },
                              { text: "Технологические карты ТОиР" },
                              {
                                text: "Поправочные коэффициенты",
                                link: "/references/technological-maps/condition-factors/",
                              },
                              { text: `${route.params.id}` },
                            ];
                          },
                        },
                      },
                      {
                        path: "edit",
                        name: "Condition Factor edit",
                        component: () =>
                          import(
                            "@/views/References/technological-maps/condition-factor/viewEdit"
                          ),
                        props: { mode: "edit" },
                        meta: {
                          breadCrumb(route) {
                            return [
                              { text: "Справочник" },
                              { text: "Технологические карты ТОиР" },
                              {
                                text: "Поправочные коэффициенты",
                                link: "/references/technological-maps/condition-factors/",
                              },
                              {
                                text: `${route.params.id}`,
                                link: `/references/technological-maps/condition-factors/${route.params.id}`,
                              },
                              { text: "Редактирование" },
                            ];
                          },
                        },
                      },
                    ],
                  },
                ],
              },
              {
                path: "cFGroup",
                component: () => import("@/views/References/index"),
                children: [
                  {
                    path: "",
                    name: "Condition Factors Groups list",
                    component: () =>
                      import(
                        "@/views/References/technological-maps/cFGroup/list"
                      ),
                    meta: {
                      breadCrumb() {
                        return [
                          { text: "Справочник" },
                          { text: "Технологические карты ТОиР" },
                          { text: "Группы поправочных коэффициентов" },
                        ];
                      },
                    },
                  },
                  {
                    path: "add",
                    name: "Condition Factor Groups create",
                    component: () =>
                      import(
                        "@/views/References/technological-maps/cFGroup/create"
                      ),
                    meta: {
                      breadCrumb() {
                        return [
                          { text: "Справочник" },
                          { text: "Технологические карты ТОиР" },
                          {
                            text: "Группы поправочных коэффициентов",
                            link: "/references/technological-maps/cFGroup/",
                          },
                          { text: "Добавление" },
                        ];
                      },
                    },
                  },
                  {
                    path: ":id",
                    component: () => import("@/views/References/index"),
                    children: [
                      {
                        path: "",
                        name: "Condition Factor Group view",
                        component: () =>
                          import(
                            "@/views/References/technological-maps/cFGroup/viewEdit"
                          ),
                        props: { mode: "view" },
                        meta: {
                          breadCrumb(route) {
                            return [
                              { text: "Справочник" },
                              { text: "Технологические карты ТОиР" },
                              {
                                text: "Группы поправочных коэффициентов",
                                link: "/references/technological-maps/cFGroup/",
                              },
                              { text: `${route.params.id}` },
                            ];
                          },
                        },
                      },
                      {
                        path: "edit",
                        name: "Condition Factor Group edit",
                        component: () =>
                          import(
                            "@/views/References/technological-maps/cFGroup/viewEdit"
                          ),
                        props: { mode: "edit" },
                        meta: {
                          breadCrumb(route) {
                            return [
                              { text: "Справочник" },
                              { text: "Технологические карты ТОиР" },
                              {
                                text: "Группы поправочных коэффициентов",
                                link: "/references/technological-maps/cFGroup/",
                              },
                              {
                                text: `${route.params.id}`,
                                link: `/references/technological-maps/cFGroup/${route.params.id}`,
                              },
                              { text: "Редактирование" },
                            ];
                          },
                        },
                      },
                    ],
                  },
                ],
              },
              {
                path: "add",
                name: "Technological map create",
                component: () =>
                  import("@/views/References/technological-maps/viewEdit"),
                props: { mode: "create" },
                meta: {
                  breadCrumb() {
                    return [
                      { text: "Справочник" },
                      { text: "Технологические карты ТОиР" },
                      {
                        text: "Группы технологических карт",
                        link: "/references/technological-maps",
                      },
                      { text: "Создание технологической карты" },
                    ];
                  },
                },
              },
              {
                path: ":techMapId",
                component: () => import("@/views/References/index"),
                children: [
                  {
                    path: "",
                    name: "Technological map view",
                    component: () =>
                      import("@/views/References/technological-maps/viewEdit"),
                    props: { mode: "view" },
                    meta: {
                      breadCrumb(route) {
                        const id = route.params.techMapId;
                        return [
                          { text: "Справочник" },
                          { text: "Технологические карты ТОиР" },
                          {
                            text: "Группы технологических карт",
                            link: "/references/technological-maps",
                          },
                          { text: `${id}` },
                        ];
                      },
                    },
                  },
                  {
                    path: "edit",
                    component: () =>
                      import("@/views/References/technological-maps/viewEdit"),
                    name: "Technological map edit",
                    props: { mode: "edit" },
                    meta: {
                      breadCrumb(route) {
                        const id = route.params.techMapId;
                        return [
                          { text: "Справочник" },
                          { text: "Технологические карты ТОиР" },
                          {
                            text: "Группы технологических карт",
                            link: "/references/technological-maps",
                          },
                          {
                            text: `${id}`,
                            link: `/references/technological-maps/${id}`,
                          },
                          { text: "Редактирование" },
                        ];
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "kinds",
            component: () => import("@/views/References/index"),
            children: [
              {
                path: "",
                name: "Kinds list",
                component: () => import("@/views/References/kinds/list"),
                meta: {
                  breadCrumb() {
                    return [{ text: "Справочник" }, { text: "Виды" }];
                  },
                },
              },
              {
                path: ":modelName",
                component: () => import("@/views/References/index"),
                children: [
                  {
                    path: "add",
                    name: "Kind create",
                    props: { mode: "create" },
                    component: () =>
                      import("@/views/References/kinds/viewEdit"),
                    meta: {
                      breadCrumb(route) {
                        const modelName = route.params.modelName;
                        return [
                          { text: "Справочник" },
                          { text: "Виды", link: "/references/kinds" },
                          { text: modelName },
                          { text: "Добавление" },
                        ];
                      },
                    },
                  },
                  {
                    path: ":id",
                    component: () => import("@/views/References/index"),
                    children: [
                      {
                        path: "",
                        name: "Kind model view",
                        props: { mode: "view" },
                        component: () =>
                          import("@/views/References/kinds/viewEdit"),
                        meta: {
                          breadCrumb(route) {
                            const modelName = route.params.modelName;
                            const id = route.params.id;
                            return [
                              { text: "Справочник" },
                              { text: "Виды", link: "/references/kinds" },
                              { text: modelName },
                              { text: `${id}` },
                            ];
                          },
                        },
                      },
                      {
                        path: "edit",
                        name: "Kind model edit",
                        props: { mode: "edit" },
                        component: () =>
                          import("@/views/References/kinds/viewEdit"),
                        meta: {
                          breadCrumb(route) {
                            const modelName = route.params.modelName;
                            const id = route.params.id;
                            return [
                              { text: "Справочник" },
                              { text: "Виды", link: "/references/kinds" },
                              { text: modelName },
                              {
                                text: `${id}`,
                                link: `/references/kinds/${modelName}/${id}/`,
                              },
                              { text: "Редактирование" },
                            ];
                          },
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Auth/Login.vue"),
  },
];

const router = new VueRouter({ routes, mode: "history" });

router.beforeEach(async (to, from, next) => {
  if (
    !store.getters.isAuthenticated &&
    to.matched.some((record) => record.meta["auth"])
  ) {
    console.log("redirect");
    next({ path: "/login", query: { redirect: to.fullPath } });
  } else next();
});

router.beforeEach(async (to, from, next) => {
  if (to.name === "Login" && store.getters.isAuthenticated) {
    if (to.query.redirect) next(to.query.redirect.toString());
    else next({ name: "Home" });
  } else next();
});

router.afterEach(async (to) => {
  if (
    !store.getters.isUserLoaded &&
    store.getters.isAuthenticated &&
    to.matched.some((record) => record.meta["auth"])
  ) {
    await store.dispatch("loadProfile");
  }
});

export default router;
