import Vue from "vue";
import Vuex from "vuex";
import axios from "@/plugins/axios";

Vue.use(Vuex);
import { works } from "@/store/works";

const token = localStorage.getItem("jwt");
if (token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

export default new Vuex.Store({
  state: {
    token: token,
    user: undefined,
  },
  getters: {
    isAuthenticated: (state) => state.token !== null,
    isUserLoaded: (state) => state.user !== undefined,
    userData: (state) => state.user,
  },
  mutations: {
    auth_success(state, payload) {
      localStorage.setItem("jwt", payload.token);
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + payload.token;
      state.token = payload.token;
    },
    auth_error(state) {
      state.token = "";
      localStorage.removeItem("jwt");
    },
    updateProfile(state, payload) {
      state.user = payload;
    },
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        axios({ url: "/apiauth", data: user, method: "POST" })
          .then((resp) => {
            const token = resp.data["data"]["token"];
            commit("auth_success", { token: token });
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error");
            reject(err);
          });
      });
    },
    async loadProfile({ commit }) {
      const res = await axios({
        url: "/me",
        method: "GET",
      });
      if (res.status === 200) {
        commit("updateProfile", res.data["data"]);
      }
    },
  },
  modules: {
    works,
  },
});
