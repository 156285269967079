export const works = {
  state: {
    status: "",
    orderInfo: {
      id: null,
      name: "",
      createdAt: new Date().toLocaleDateString(),
      modifiedAt: new Date().toLocaleDateString(),
      dateWorkFrom: "",
      dateWorkTo: "",
      kindWorksId: null,
      priorityId: null,
      createByUserId: null,
      responsibleUserId: null,
      subjectId: null,
      cost: null,
      statusKind: "",
      map: [57.36, 61.41],
    },
    worksItems: [],
  },
  getters: {
    getOrderInfo(state) {
      return state.orderInfo;
    },
    getWorkItems(state) {
      return state.worksItems;
    },
    getWorkStatus(state) {
      return state.status;
    },
  },
  mutations: {
    setOrderInfo(state, payload) {
      state.orderInfo = payload;
    },
    setWorkItems(state, payload) {
      state.worksItems = payload;
    },
    setWorkStatus(state, payload) {
      if (typeof payload === "string") {
        state.status = payload;
      }
    },
  },
  actions: {
    saveWork() {
      console.log("Save order");
    },
  },
};
