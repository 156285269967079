import Vue from "vue";
import App from "./App.vue";
import router from "./router.js";
import store from "./store";
import axios from "@/plugins/axios";
import VueLodash from "vue-lodash";
import lodash from "lodash";

// import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import Notifications from "vue-notification";

import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
import VComp from "@vue/composition-api";

import YmapPlugin from "vue-yandex-maps";

Vue.use(YmapPlugin, {
  apiKey: process.env.VUE_APP_YMAPS_KEY,
  lang: "ru_RU",
  coordorder: "latlong",
  enterprise: false,
  version: "2.1",
  debug: true,
});

Vue.config.productionTip = false;
Vue.use(VueLodash, { lodash: lodash });
Vue.use(Notifications);
Vue.use(VComp);
Vue.prototype.$http = axios;

new Vue({
  router,
  store,

  render: function (h) {
    return h(App);
  },
}).$mount("#app");
