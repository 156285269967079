<template>
  <div id="app">
    <Notify />
    <router-view />
  </div>
</template>

<script>
import Notify from "@/components/Notify";

export default {
  components: {
    Notify,
  },
};
</script>

<style lang="scss">
@import "src/scss/bootstrap";
@import "src/scss/styles";

@font-face {
  font-family: "Gilory";
  src: url("./assets/fonts/Gilory/Gilroy-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Gilory";
  src: url("./assets/fonts/Gilory/Gilroy-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilory";
  src: url("./assets/fonts/Gilory/Gilroy-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
}

* {
  margin: 0;
  box-sizing: border-box;
}

// TODO: Remove
// THIS IS HOT FIX
body {
  background-color: $main-2;
}

#app {
  font-family: "Gilory", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-main;
  background-color: $main-2;
  min-height: 100vh;
}
</style>
